<template>
    <div class="support-component">
        <v-btn large @click="phone_dialog = true" fab color="success"><v-icon>mdi-phone</v-icon></v-btn>
        <v-btn large @click="openTelegram" fab color="success"><img src="/image/telegram_logo.png" width="64" alt=""></v-btn>
        <v-dialog width="500" v-model="phone_dialog">
            <v-card outlined rounded="xl">
                <v-card-title>{{ $t('components.support_component.make_call') }}
                    <v-spacer></v-spacer>
                    <v-icon @click="phone_dialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn block @click="openPhone" rounded color="success"><v-icon>mdi-phone</v-icon> +99890 126 80 68</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "SupportComponent",
    data(){
        return {
            phone_dialog: false
        }
    },
    methods:{
        openPhone(){
            window.open("tel: +998901268068", "_blank")
        },
        openTelegram(){
            window.open("https://t.me/onlinemed_uz", "_blank")
        }
    }
}
</script>

<style scoped>
.support-component{
    position: fixed;
    bottom: 104px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

</style>
<template>
  <div>

    <h1 class="text-center text-404">404</h1>
    <h3 class="text-center text-404-description">{{ $t('pages.page.not_found_page') }}</h3>
  </div>
</template>

<script>

export default {
  name: "PageNotFound",
  components: {
  },

}
</script>

<style scoped>


</style>